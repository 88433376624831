
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-4" id="invoice" role="tabpanel" aria-labelledby="invoice-tab">
          <div class="d-sm-flex mb-5" data-view="print"><span class="m-auto"></span>
          </div>
          <div id="print-area">
            <div class="row">
              <div class="col-md-12 text-center">
                <img src="@/assets/images/sarit_logo.png" style="width: 150px;height: 70px" class="img img-responsive" />
                <br/>
                <br/>
                <h3>Voucher Redemption Statement </h3>
              </div>
            </div>

            <div class="row">
               <div class="col-md-12 text-center">
                   <p>
                     Phone: +254 111 040 600 | Email: info@saritcentre.com | Location: Westlands, Nairobi

                   </p>
               </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <p><strong>Statement No: </strong> {{ voucherRedemption.id }} </p>
                <p><strong>Merchant: </strong> {{ voucherRedemption.outlet.name}} </p>
                <p><strong>Net Value in KES: </strong> {{ voucherRedemption.postingAmount | number('0,0') }}</p>
              </div>
              <div class="col-md-6 text-sm-right">
                <p><strong>Dated: </strong> {{ voucherRedemption.postingDate }}</p>

              </div>
            </div>
            <div class="mt-3 mb-4 border-top"></div>

            <div class="row">
              <div class="col-md-12 table-responsive">
                <table class="table table-hover mb-4">
                  <thead class="bg-gray-300">
                  <tr>
                    <th scope="col">Redemption Date</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Amount (in KES)</th>
                    <th scope="col">Voucher Code</th>
                    <th scope="col">Redeemed By</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="voucherRedemptionItem in voucherRedemptionItems">
                      <td>
                        <span v-if="voucherRedemptionItem.customerGiftRedemption">
                            {{ voucherRedemptionItem.customerGiftRedemption.transactionDate }}
                        </span>
                        <span v-else-if="voucherRedemptionItem.institutionGiftRedemption">
                            {{ voucherRedemptionItem.institutionGiftRedemption.transactionDate }}
                        </span>
                      </td>
                      <td>
                        <span v-if="voucherRedemptionItem.customerGiftRedemption">
                            {{ voucherRedemptionItem.customerGiftRedemption.customer.name }}
                        </span>
                        <span v-else-if="voucherRedemptionItem.institutionGiftRedemption">
                            {{ voucherRedemptionItem.institutionGiftRedemption.institution.nameOfInstitution }} - {{ voucherRedemptionItem.institutionGiftRedemption.recipientName }}
                        </span>
                      </td>
                      <td>
                         <span v-if="voucherRedemptionItem.customerGiftRedemption">
                            {{ voucherRedemptionItem.customerGiftRedemption.amount }}
                        </span>
                        <span v-else-if="voucherRedemptionItem.institutionGiftRedemption">
                            {{ voucherRedemptionItem.institutionGiftRedemption.amount }}
                        </span>
                      </td>
                      <td>
                        <span v-if="voucherRedemptionItem.customerGiftRedemption">
                            {{ voucherRedemptionItem.customerGiftRedemption.voucherCode }}
                        </span>
                        <span v-else-if="voucherRedemptionItem.institutionGiftRedemption">
                            {{ voucherRedemptionItem.institutionGiftRedemption.voucherCode }}
                        </span>
                      </td>
                      <td>
                        <span v-if="voucherRedemptionItem.customerGiftRedemption">
                            {{ voucherRedemptionItem.customerGiftRedemption.redeemedBy.name }}
                        </span>
                        <span v-else-if="voucherRedemptionItem.institutionGiftRedemption">
                            {{ voucherRedemptionItem.institutionGiftRedemption.redeemedBy.name }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <!-- ==== / Print Area =====-->
        </div>

      </div>
    </div>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'
  import VueHtmlToPaper from 'vue-html-to-paper';

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      invoices:[],
      start_date:"all",
      end_date:"all",
      voucherRedemptionItems:[],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      voucherRedemption:{
        created: "",
        id: "",
        postingAmount: "",
        postingDate: "",
        postingEndDate: "",
        postingStartDate: "",
        outlet: {
          name:"",
          phoneNumber:"",
          email:""
        },
        updated: ""
      }
    };
  },
  computed:{
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    },
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  mounted (){
    this.getVoucherRedemptionsPostingById();
    this.getVoucherRedemptionItemsData();

  },
  methods: {
    ...mapActions(["getVoucherRedemptionsPosting", "getVoucherRedemptionItems"]),

    getVoucherRedemptionsPostingById(){

      let self = this;

      self.isLoading = true;

      this.getVoucherRedemptionsPosting({voucherId: this.$route.params.id})

        .then(function (voucherRedemption) {
          self.isLoading = false;
          self.voucherRedemption = voucherRedemption;

        })
        .catch(function (error) {

          self.isLoading = false;


        })
    },
    getVoucherRedemptionItemsData(){

      let self = this;

      this.getVoucherRedemptionItems({voucherId: this.$route.params.id})
          .then(function (voucherRedemptionItems) {

            self.isLoading = false;

            self.voucherRedemptionItems = voucherRedemptionItems;
          })
          .catch(function (error) {

            self.isLoading = false;

          })
    }
  }
};
</script>
